import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { cloneObject, stringBuilder } from '@zipari/web-utils';
import { accordion, fade, slideRight } from '@zipari/shared-sbp-animations';
import { AnalyticsService } from '@zipari/shared-sbp-services';
import { buttonConfig, modelAttributeConfig } from './plan-enroll.constants';

@Component({
    selector: 'plan-enroll',
    templateUrl: './plan-enroll.component.html',
    styleUrls: ['./plan-enroll.component.scss'],
    animations: [fade, slideRight, accordion],
})
export class PlanEnrollComponent implements OnChanges {
    @Input() plan;
    @Input() disableEnrollButton: boolean;
    @Input() showEnrollButton: boolean = true;

    @Output('enroll') enroll = new EventEmitter<any>();

    config;
    buttonConfig = buttonConfig;

    constructor(public analyticsService: AnalyticsService) {}

    ngOnChanges() {
        this.formatPlanToHavePrice(this.plan);

        this.config = cloneObject(modelAttributeConfig);

        this.config.label = stringBuilder(this.config.label, { plan: this.plan });
        this.config.value = stringBuilder(this.config.value, { plan: this.plan });
    }

    formatPlanToHavePrice(plan) {
        if (plan && !plan.price) {
            plan.price = 0;
        }
    }
}
