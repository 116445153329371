import { Component, OnInit, Input } from '@angular/core';
import { slideRight } from '@zipari/shared-sbp-animations';

@Component({
    selector: 'slide-out',
    templateUrl: './slide-out.component.html',
    styleUrls: ['./slide-out.component.scss'],
    animations: [slideRight],
})
export class SlideOutComponent implements OnInit {
    @Input() config;

    constructor() {}

    ngOnInit() {}
}
