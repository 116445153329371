import { ButtonLevels, ButtonConfig } from '@zipari/shared-ds-util-button';
import { PrintConfig } from '@zipari/shared-sbp-services';

export interface CountyConfig {
    county_code?: string;
    county_name?: string;
    has_plans_in_service?: boolean;
    id?: number;
    plan_type_name?: string[];
    plan_year?: number[];
    state_code?: string;
    state_name?: string;
    zipcodes?: [];
    coverage_effective_date?: string;
    zipcode?: string;
    has_plans: boolean;
}

export interface CurrentStepConfig {
    assignClientIfNone?: boolean;
    assignClientModalText?: { header: string; title: string };
    buttons?: ButtonConfig[];
    clientFieldsToMatch?: [];
    clientTypeConfig?: ClientTypeConfig;
    completeQuote?: { endpoint: string };
    displayAllDocuments?: boolean;
    editConfirmation?: { modalConfig: {}; footerButtons: {} };
    enroll?: { allow: boolean; selected: SelectedConfig; unselected: UnSelectedConfig };
    label?: string;
    modalButtons?: { close: string; saveNew: string; saveExisting: string };
    primaryPlanTypes?: PrimaryPlanTypeConfig;
    printConfig?: PrintConfig;
    queryParams?: string;
    removePlanConfig?: RemovePlanConfig;
    removePlanIcon?: string;
    reviewStepperHeader?: { label: string; description: string };
    showRemovePlanIcon?: boolean;
    showReviewStepperFooterEffectiveLabel?: boolean;
    slideOutConfig?: { reviewQuoteDescription: string; newClientConfig: []; existingClientConfig: ExistingClientConfig };
    type?: string;
    updateSingleKey?: boolean;
    pageSize?: number;
}

export interface SelectedConfig {
    content: string;
    disabled: boolean;
    icon: string;
    level: string;
}

export interface ClientTypeConfig {
    ariaLabel?: string;
    customFormElement?: boolean;
    label: string;
    options: [];
    prop: string;
    type: string;
}

export interface PrimaryPlanTypeConfig {
    medicare_advantage?: boolean;
    medicare_part_d?: boolean;
    medicare_supplemental?: boolean;
    mapd?: boolean;
    d_snp?: boolean;
}

export interface UnSelectedConfig {
    content: string;
    disabled: boolean;
    icon: string;
    level: string;
}

export interface RemovePlanConfig {
    btnConfig: ReviewBtnConfig;
    header: {
        showClose: boolean;
        title: string;
    };
    primary: string;
    secondary: string;
}

export interface ExistingClientConfig {
    ariaLabel: string;
    customFormElement: boolean;
    endpoint: string;
    endpointDebounce: number;
    endpointTemplate: {
        label: string;
        value: string;
    };
    icon: string;
    label: string;
    noResults: string;
    options: [];
    prop: string;
    type: string;
}

export interface ReviewBtnConfig {
    cancel: {
        level: string;
        content: string;
    };
    yesRemove: {
        level: string;
        content: string;
    };
}

export interface Steps {
    review: {
        label?: string;
        icon?: string;
        type?: string;
        attributes: [];
    };
}
