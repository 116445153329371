import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getValue } from '@zipari/web-utils';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { accordion, fade, slideLeft } from '@zipari/shared-sbp-animations';
import { NavigationService } from '../navigation.service';
import { NavItem, Toolbar } from '../navigation.configuration';

@Component({
    selector: 'mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
    animations: [accordion, fade, slideLeft],
})
export class MobileMenuComponent implements OnInit {
    @Input() isLoggedIn: boolean = false;
    @Output() openContactModal = new EventEmitter();
    @Output() handleLogout = new EventEmitter();
    toolbarMenuOpen: boolean = false;
    showLangSelector: boolean = false;
    mobileMenuSections: Array<NavItem[]>;
    private navigationMenuSubscription: Subscription;

    constructor(public navigationService: NavigationService, private router: Router) {}

    ngOnInit(): void {
        this.getNavigationMenuSections();
    }

    getNavigationMenuSections(): void {
        this.navigationMenuSubscription = this.navigationService.navigationConfig$.subscribe((navConfig) => {
            const sections = getValue(navConfig, 'menu.sections');
            this.mobileMenuSections =
                sections &&
                sections
                    ?.map((section: NavItem[]) => {
                        return section.filter(this.getShowItem);
                    })
                    .filter((menuSection: NavItem[]) => menuSection.length > 0);
        });
    }

    getShowItem = (item) => {
        let show = true;
        switch (item.auth) {
            case 'loggedIn':
                show = this.isLoggedIn;
                break;

            case 'loggedOut':
                show = !this.isLoggedIn;
                break;

            default:
                show = true;
                break;
        }

        return show;
    };

    callback(type) {
        switch (type) {
            case 'contact':
                this.navigationService.toggleMobileMenu();
                this.openContactModal.emit();
                break;

            case 'logout':
                this.handleLogout.emit();
                break;

            case 'toggleLang':
                this.showLangSelector = !this.showLangSelector;
                break;
        }
    }

    linkClick(event, link) {
        this.navigationService.selectNavLink(event, link);
        this.callback(link.onClick);
    }

    itemClick(item) {
        this.navigationService.mobileMenuOpen = false;
        this.callback(item.onClick);
    }

    get language() {
        return JSON.parse(window.localStorage.getItem('zipLangISO'));
    }

    isLoginOrRegisterMenuVisibile(link: NavItem): boolean {
        return !this.isLoggedIn ? !this.router.url.includes(link.route) : true;
    }

    isMobileToolbarDisplay(): boolean {
        const toolbarExists: Toolbar = this.navigationService.config?.toolbar;

        return !!toolbarExists && toolbarExists?.routes?.length > 1 && !!this.isLoggedIn;
    }

    ngOnDestroy(): void {
        this.navigationMenuSubscription?.unsubscribe();
    }
}
