import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { cloneObject, getValue, stringBuilder } from '@zipari/web-utils';
import { fade, slideRight } from '@zipari/shared-sbp-animations';
import { validCXEvents, validGAEvents, validPosthogEvents } from '@zipari/shared-sbp-constants';
import { AnalyticsService, AuthService, BrokerAssistanceService, ConfigService, LoggerService } from '@zipari/shared-sbp-services';
import { Subscription } from 'rxjs';

import { brokerAssistanceConfigsFromConstantFile } from './navigation.constants';
import { NavigationService } from './navigation.service';
import { NavItem } from './navigation.configuration';

@Component({
    selector: 'navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    animations: [fade, slideRight],
})
export class NavigationComponent implements AfterViewInit, OnInit, OnDestroy {
    public isLoggedIn = false;
    public queryParams;
    public brokerAssistanceConfigs;

    @ViewChild('toolbarContainerRef') toolbarRef: ElementRef;

    public routes = {
        zshop: 'shopping',
    };

    @Output() query = new EventEmitter<string>();
    newConfigCheck;
    brokerSub;
    brokerAssistanceInfoCheck;
    busy: Subscription;

    constructor(
        public loggerService: LoggerService,
        public brokerAssistanceService: BrokerAssistanceService,
        public authService: AuthService,
        public navigationService: NavigationService,
        private router: Router,
        private route: ActivatedRoute,
        public configService: ConfigService,
        public http: HttpClient,
        public analyticsService: AnalyticsService
    ) {}

    public get isNavHidden() {
        return this.navigationService.getNavHidden();
    }

    get configs() {
        return this.configService.getPageConfig('navigation') || this.configService.configs.navigation;
    }

    public isButtonVisible(button, route: string = null) {
        let shouldBeVisible = !button.hideOnLogout || (button.hideOnLogout && !!this.authService.loggedInUser);

        if (button.type === 'auth' && route) {
            shouldBeVisible = !this.router.url.includes(route);
        } else if (this.router.url === button.route) {
            shouldBeVisible = false;
        }

        if (button.blacklist) {
            button.blacklist.forEach((workflow) => {
                if (this.router.url.includes(workflow)) {
                    shouldBeVisible = false;
                }
            });
        }

        return shouldBeVisible;
    }

    public get brokerSearchSub(): Subscription {
        return this.brokerAssistanceService.brokerSearchSub;
    }

    ngOnInit() {
        /*   when a user is logging in with the url they from their email like this
         *    http://dtc3-fhp.local.zipari.net:8000/shopping/individual/48776?fromEmail=true,
         *    configService.getPageConfigs tries to grab navigation configs on a parent level so it becomes undefined
         */

        if (this.configs?.contact_us?.brokerAssistanceConfigs) {
            this.brokerAssistanceInfoCheck = true;
        }

        const brokerAssistanceConfigs: any = this.brokerAssistanceInfoCheck
            ? cloneObject(this.configs.contact_us.brokerAssistanceConfigs)
            : brokerAssistanceConfigsFromConstantFile;

        this.authService.appUserData.subscribe((response) => {
            this.isLoggedIn = !!response;
        });
        this.queryParams = cloneObject(this.route.snapshot.queryParams);

        this.newConfigCheck = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && this.configService.marketSegmentIsValidInApp(this.route.snapshot)) {
                this.navigationService.initConfig();
            }
        });

        this.brokerSub = this.brokerAssistanceService.assistingBroker$.subscribe((val) => {
            if (val) {
                this.brokerAssistanceConfigs = brokerAssistanceConfigs.map((config) => {
                    const newConfig = { ...config };

                    newConfig.value = stringBuilder(newConfig.value, val);

                    return newConfig;
                });
            }
        });
    }

    ngAfterViewInit() {
        // Notify navigation service of the toolbar's height
        const height: number = this.toolbarRef ? this.toolbarRef.nativeElement.getBoundingClientRect().height : 0;
        this.navigationService.setToolbarHeight(height);
    }

    ngOnDestroy() {
        if (this.busy) {
            this.busy.unsubscribe();
        }

        if (this.brokerSearchSub) {
            this.brokerSearchSub.unsubscribe();
        }
    }

    onNavButtonClick(button) {
        if (button.route) {
            this.analyticsService.dispatchAnalytics({
                GAKey: validGAEvents['navigate_to_workbench'],
                CXKey: validCXEvents['navigate-to_workbench'],
                PosthogKey: validPosthogEvents['navigate_to_workbench'],
            });
        }

        switch (button.onClick) {
            case 'url':
                window.open(button.url, '_blank');
                break;
            case 'route':
                this.router.navigate(button.route);
                break;
            case 'contact':
                this.navigationService.openContactModal();
                break;
            case 'logout':
                this.handleLogout();
                break;
        }
    }

    search(query: string) {
        this.query.emit(query);
    }

    closeLink(link) {
        link.open = false;
    }

    handleLogin() {
        this.authService.handleExternalLogin();
        this.router.navigate([this.configService.appRoute, 'login']);
    }

    handleLogout() {
        this.authService.handleLogout();
        this.sendLogoutDataAnalytics();
    }

    handleRegister() {
        const globalConfig: any = this.configService.getPageConfig('global');

        if (globalConfig.externalRegister) {
            window.location.assign(globalConfig.externalRegister);
        } else {
            this.router.navigate([this.configService.appRoute, 'register']);
        }
    }

    logoClicked() {
        const globalConfig = this.configService.getPageConfig('global');
        const noRoute = globalConfig['logoNoRoute'];
        let route: Array<string> = [this.configService.appRoute];

        if (this.configs.openLogoInNewTab && !!this.configs.logo_url) {
            window.open(this.configs.logo_url);
        } else if (this.configs.openLogoInSameTab && !!this.configs.logo_url) {
            window.open(this.configs.logo_url, '_self');
        } else {
            if (!!this.configs.logo_url) {
                route = [this.configs.logo_url];
            } else if (!noRoute) {
                if (this.configService.activeRoute) {
                    route.push(this.configService.activeRoute);
                }

                if (this.configService.activeRouteSegmentForLogo) {
                    route.push(this.configService.activeRouteSegmentForLogo);
                }
            }

            this.router.navigate(route, { queryParams: { stayOnWorkbench: true } });
        }
    }

    IsNavIconDisplay(navItem: NavItem): boolean {
        return !(navItem?.auth === 'loggedIn' && !this.authService.loggedInUser);
    }

    sendDataAnalytics(details) {
        this.analyticsService.dispatchAnalytics(
            {
                GAKey: validGAEvents['contact_us_flyout_details'],
                CXKey: validCXEvents['contact_us_flyout_details'],
                PosthogKey: validPosthogEvents['contact_us_flyout_details'],
            },
            { ...details }
        );
    }

    sendLogoutDataAnalytics() {
        const dictionary_attributes = {
            eventLabel: 'user-logout',
            pageName: 'Successful Logout',
        };

        this.analyticsService.dispatchAnalytics(
            {
                PosthogKey: validPosthogEvents.user_logout,
            },
            { ...dictionary_attributes }
        );
    }

    queryParmsToString(queryParams) {
        let queryParamString = '';
        if (queryParams) {
            const queryParamList = Object.entries(queryParams);
            queryParamString = queryParamList.reduce<string>((prev, current, index) => {
                const ampersand = index > 0 ? '&' : '';
                const param = current[0];
                const value = current[1];
                return prev + `${ampersand}${param}=${value}`;
            }, '?');
        }
        return queryParamString;
    }

    get notifications() {
        return Number(window.sessionStorage.getItem('zipNotificationsUnread'));
    }
}
