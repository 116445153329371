import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReadMoreComponent } from './read-more.component';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [ReadMoreComponent],
    imports: [CommonModule, SharedModule],
    exports: [ReadMoreComponent],
})
export class ReadMoreModule {}
