<nav class="nav">
    @if(isUtilityNavBarEnable) {
    <navigation data-test-id="utilityNavigationBar"></navigation>
    } @else {
    <div class="container u-flex u-alignCenter">
        <a class="nav__logo" tabindex="0" [routerLink]="config?.logoRoute"> </a>

        <div class="enrollment__subheader" *ngIf="showSubNav">
            <div *ngIf="showAppID && appID">
                <p class="t-data t-bold">Application #{{ appID }}</p>
            </div>

            <div *ngIf="enrollmentSubheader && enrollmentSubheader.enable" class="enrollment__subheader">
                <p class="t-data t-bold">{{ enrollmentSubheader?.content }}</p>
            </div>
        </div>

        <div class="u-fillRemaining u-flex u-alignCenter u-justifyEnd u-maxY">
            <a class="enrollment__exit" (click)="exitEnrollment()">
                <i class="icon icon--medium">
                    {{ config?.exitEnrollmentIcon }}
                </i>
                <p class="t-data t-bold" [innerHTML]="config?.exitEnrollmentText"></p>
            </a>
        </div>
    </div>
    }
</nav>
