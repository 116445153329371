import { ModalConfig, ModalHeader, ModalTypes } from '@zipari/shared-ds-util-modal';

export const TOP_NAV_HEIGHT = 60; // 3.75rem

export const emailContactConfig = [
    {
        label: 'Customer Support',
        value: 'email@domain.com',
        format: 'EMAIL',
    },
];

export const brokerAssistanceConfigsFromConstantFile = [
    {
        label: 'Broker',
        value: '${first_name} ${last_name}',
    },
    {
        label: 'Id',
        value: '${broker_id}',
    },
    {
        label: 'Phone',
        value: '${phone}',
        format: 'PHONE',
    },
    {
        label: 'Email',
        value: '${email_address}',
        format: 'EMAIL',
    },
];

export const contactUsModalConfig: ModalConfig = new ModalConfig({
    type: ModalTypes.flyout,
    backdrop: true,
    clickOutside: true,
    header: new ModalHeader({
        title: 'Contact Us',
        showClose: true,
    }),
});
