<header class="ComparePlansHeader">
    <div class="container">
        <section *ngIf="highlightedPlans.length" class="ComparePlansHeader__Row">
            <div class="ComparePlansHeader__Cell" *ngFor="let plan of plans">
                <status *ngIf="getHighlightedPlanHeader(plan)" [config]="{ label: getHighlightedPlanHeader(plan) }" status="info"></status>
            </div>
        </section>

        <section class="ComparePlansHeader__Row">
            <div class="ComparePlansHeader__Cell">
                <zip-button
                    class="ComparePlansHeader__BackButton"
                    [config]="{
                        level: 'text',
                        icon: 'chevron_left',
                        fill: 'primary',
                        content: comparePlansConfig?.label?.backToPlans || 'Back to All Plans'
                    }"
                    (zipButtonClicked)="goBackToAllPlans()"
                >
                </zip-button>
            </div>

            <div
                class="ComparePlansHeader__Cell"
                [ngClass]="{ 'ComparePlansHeader__Cell--Scrolled': isScrolled }"
                *ngFor="let plan of plans"
            >
                <div [ngSwitch]="comparePlansConfig?.headerCardType" class="ComparePlansHeader__Cell">
                    <ng-container *ngSwitchCase="'RoundedCorner'">
                        <h6 class="ComparePlansHeader__Title t-bold" [innerHTML]="plan.display_name"></h6>
                        <section class="ComparePlansHeader__Rating" *ngIf="!!plan.star_rating && comparePlansConfig?.showStarRating">
                            <star-rating [rating]="plan.star_rating"></star-rating>
                        </section>
                        <div class="u-justifyBetween u-alignCenter">
                            <div *ngIf="plan.subsidyText?.value; else withoutSubsidy">
                                <h6 class="t-bold" [innerHTML]="plan.subsidyText?.discountedPrice"></h6>
                                <div>
                                    <p class="t-data" [innerHTML]="plan.subsidyText?.subsidyLabel"></p>
                                    <p class="t-data" [innerHTML]="plan.subsidyText?.originalPriceLabel"></p>
                                    <br />
                                </div>
                            </div>
                            <ng-template #withoutSubsidy>
                                <span class="t-bold" [innerHTML]="(comparePlansConfig?.text || comparePlansConfig?.label)?.monthly_premium">
                                </span>
                                <span
                                    class="ComparePlansHeader__PremiumPrice t-data"
                                    [innerHTML]="plan.price || 0 | currency : 'USD' : 'symbol' : '1.0'"
                                >
                                </span>
                                <span class="t-caption" [innerHTML]="comparePlansConfig?.premiumSuffix"></span>
                            </ng-template>
                            <div *ngFor="let highlight of getHighlights(plan) | keyvalue">
                                <div class="u-flex u-justifyBetween u-alignCenter">
                                    <p class="t-data" [innerHTML]="highlight.value.label"></p>
                                    <h6 [innerHTML]="highlight.value.value"></h6>
                                </div>
                            </div>
                        </div>
                        <div class="u-flex u-justifyCenter">
                            <zip-button
                                *ngIf="comparePlansConfig?.showEnroll"
                                level="high"
                                [content]="comparePlansConfig?.enrollButton || comparePlansConstantText.enrollText"
                                (zipButtonClicked)="enroll(plan)"
                            >
                            </zip-button>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <h6 class="ComparePlansHeader__Title t-bold" [innerHTML]="plan.display_name"></h6>
                        <section class="ComparePlansHeader__Rating" *ngIf="!!plan.star_rating && comparePlansConfig?.showStarRating">
                            <star-rating [rating]="plan.star_rating"></star-rating>
                        </section>
                        <div class="u-flex u-justifyBetween u-alignCenter">
                            <div class="premium" *ngIf="plan.subsidyText?.value; else withoutSubsidy">
                                <p class="t-bold" [innerHTML]="plan.subsidyText?.discountedPrice"></p>
                                <p class="t-data" [innerHTML]="plan.subsidyText?.subsidyLabel"></p>
                                <p class="t-data" [innerHTML]="plan.subsidyText?.originalPriceLabel"></p>
                                <br />
                            </div>
                            <ng-template #withoutSubsidy>
                                <p class="t-data">
                                    <span
                                        class="t-bold"
                                        [innerHTML]="(comparePlansConfig?.text || comparePlansConfig?.label)?.monthly_premium"
                                    >
                                    </span>
                                    <span
                                        class="ComparePlansHeader__PremiumPrice t-data"
                                        [innerHTML]="plan.price || 0 | currency : 'USD' : 'symbol' : '1.0'"
                                    >
                                    </span>
                                    <span class="t-caption" [innerHTML]="comparePlansConfig?.premiumSuffix"></span>
                                </p>
                            </ng-template>

                            <zip-button
                                *ngIf="comparePlansConfig?.showEnroll"
                                level="high"
                                [content]="comparePlansConfig?.enrollButton || comparePlansConstantText.enrollText"
                                (zipButtonClicked)="enroll(plan)"
                            >
                            </zip-button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </section>
    </div>
</header>
