import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WINDOW } from './window.service';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';

@Injectable()
export class AuthGuardService {
    constructor(
        public configService: ConfigService,
        public authService: AuthService,
        public router: Router,
        public route: ActivatedRoute,
        @Inject(WINDOW) private window: Window
    ) {}

    canActivate(): boolean {
        const globalConfig = this.configService.getPageConfig<any>('global');
        const handleAuth = globalConfig.handleAuth;
        const authExceptions = globalConfig.authExceptions || [];
        const fitAnyExceptions = authExceptions.find((authException) => this.window.location.href.indexOf(authException) >= 0);
        const pagesRemoved = globalConfig.pagesRemoved || [];
        const pageRemoved = pagesRemoved.find((page) => this.window.location.href.indexOf(page) >= 0);

        if (((handleAuth || fitAnyExceptions) && !this.authService.loggedInUser) || pageRemoved) {
            // Check if we should preserve the route the user is attempting to route to
            // depending on which previous route the user is coming from
            const preserveRoutingWhenUnauthenticatedFromRoutes: Array<string> =
                globalConfig.preserveRoutingWhenUnauthenticatedFromRoutes || [];
            const previousRouteUrl = window.location.pathname;
            const routeBeingCanceled = this.router.getCurrentNavigation()?.finalUrl?.fragment;
            const shouldPreserveCanceledRoute = preserveRoutingWhenUnauthenticatedFromRoutes.some((routeUrl) =>
                previousRouteUrl?.includes(routeUrl)
            );

            this.authService.handleExternalLogin();
            this.authService.replayPath = shouldPreserveCanceledRoute && routeBeingCanceled ? routeBeingCanceled : previousRouteUrl;

            this.router.navigate([this.configService.appRoute, 'login']);
            return false;
        }
        return true;
    }
}
