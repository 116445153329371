import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule, FormControlModule, ZipBusyModule } from '@zipari/design-system';
import { NavigationModule } from '../../../../../modules/src/lib/navigation/navigation.module';
import { ReadMoreModule } from '../../../../../modules/src/lib/read-more/read-more.module';

import { CoverageEffectiveDateComponent } from './coverage-effective-date.component';

@NgModule({
    declarations: [CoverageEffectiveDateComponent],
    imports: [CommonModule, FormsModule, ButtonModule, FormControlModule, NavigationModule, ZipBusyModule, ReadMoreModule],
    exports: [CoverageEffectiveDateComponent],
})
export class CoverageEffectiveDateModule {}
