<div class="modal custom--modal modal--panel" @slideRight>
    <header class="modal__header">
        <p class="t-data t-bold" [innerHTML]="config.header"></p>
    </header>

    <section class="modal__body">
        <div class="modal__message">
            <h4 class="t-bold" [innerHTML]="config.warningMessage.header"></h4>
            <p class="t-data" [innerHTML]="config.warningMessage.body"></p>
        </div>
        <div *ngIf="existingClientsData">
            <form-control
                (click)="handleClientSelected()"
                [config]="config.existingClientListConfig"
                [group]="existingClientForm"
                [control]="config.existingClientListConfig.prop"
            ></form-control>
        </div>
    </section>

    <footer class="modal__footer">
        <zip-button [config]="cancelButtonConfig" (click)="onCancel()"> </zip-button>

        <ng-container *ngIf="!!isExistingClientSelected; else defaultButton">
            <zip-button [config]="existingClientButtonConfig" (click)="onContinue()"></zip-button>
        </ng-container>

        <ng-template #defaultButton>
            <zip-button [config]="saveButtonConfig" [disabled]="!!isSaveButtonDisabled" (zipButtonClicked)="onSave()"></zip-button>
        </ng-template>
    </footer>
</div>
