import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { getValue } from '@zipari/web-utils';
import { slideRight } from '@zipari/shared-sbp-animations';
import { ClientData } from '@zipari/shared-sbp-constants';

import { modalViewType } from './add-or-edit-modal.constants';

@Component({
    selector: 'add-or-edit-modal',
    templateUrl: './add-or-edit-modal.component.html',
    styleUrls: ['./add-or-edit-modal.component.scss'],
    animations: [slideRight],
})
export class AddOrEditModalComponent implements OnInit, OnChanges {
    @Input() config;
    @Input() successResponse;
    @Input() errorResponse;
    @Input() viewProfile;
    @Input() existingClientsData: ClientData[];
    @Input() editClient: boolean;
    @Input() clientExists: boolean;
    @Input() addRowForm: FormGroup = new FormGroup({});

    @Output() formBuilt = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() save = new EventEmitter();
    @Output() continue = new EventEmitter();

    @ViewChild('contentEl', { static: true }) contentEl: ElementRef;

    isModalOpen = false;

    attributes;
    viewType: modalViewType.form | modalViewType.success | modalViewType.error | modalViewType.existingClientList = modalViewType.form;

    emailError: string;
    phoneError: string;
    isSaveButtonDisabled: boolean;
    isNextDisabled = false;

    constructor(private formControlService: FormControlService) {}

    ngOnInit() {
        this.buildAddRowForm();
    }

    public scrollToTop(): void {
        if (this.contentEl) {
            this.contentEl.nativeElement.scrollTop = 0;
        }
    }

    ngOnChanges() {
        if (this.successResponse) {
            this.viewType = modalViewType.success;
            this.setAttributes();
        } else if (this.errorResponse) {
            this.viewType = modalViewType.error;
            this.emailError = this.errorResponse.error?.errors[0]?.user_error?.email || '';
            this.phoneError = this.errorResponse.error?.errors[0]?.user_error?.phone_number || '';
        } else if (this.clientExists) {
            this.viewType = modalViewType.existingClientList;
            this.isSaveButtonDisabled = !this.editClient;
            this.scrollToTop();
        }
    }

    // flatten attributes from config for display
    setAttributes() {
        const attributes = Array.isArray(this.config.attributes) ? this.config.attributes : this.config.attributes.attributes;
        this.attributes = attributes.reduce((array, attr) => {
            const title = attr.title;
            const value = getValue(this.successResponse, attr.prop);
            if (title || value) {
                array.push({ title, value, label: attr.label });
            }
            return array;
        }, []);
    }

    buildAddRowForm() {
        const sections = getValue(this.config, 'form.groups');

        sections.forEach((section) => {
            section.controls.forEach((control) => {
                this.formControlService.addControlToFormGroup(this.addRowForm, control);
            });
        });

        this.formBuilt.emit();
    }

    onCancel() {
        this.cancel.emit();
    }

    // if form !valid highlight required/invalid fields
    onSave(isCreateNewClientSelected?: boolean): void {
        if (this.addRowForm.valid) {
            this.isNextDisabled = true;
            this.save.emit({
                editClient: this.editClient,
                createNewClient: isCreateNewClientSelected,
                clientData: this.addRowForm.value,
            });
        } else {
            const controls = this.addRowForm.controls;
            Object.keys(controls).forEach((c) => controls[c].markAsTouched());
        }
    }

    onContinue(selectedClientId?: number): void {
        const emitValue: number = selectedClientId ? selectedClientId : this.successResponse.id;

        this.continue.emit(emitValue);
    }

    onRetry(): void {
        this.errorResponse = null;
        this.viewType = modalViewType.form;
    }
}
