import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { ModalConfig } from '@zipari/shared-ds-util-modal';
import { cloneObject, SafeNumberPipe, stringBuilder } from '@zipari/web-utils';
import { accordion, fade, slideRight } from '@zipari/shared-sbp-animations';
import { validCXEvents, validGAEvents, validPosthogEvents } from '@zipari/shared-sbp-constants';
import { AnalyticsService, ConfigService, PlanUtilService } from '@zipari/shared-sbp-services';

import { Benefit, Plan, PlanCxContext, PlanIconConfig, PlanModalContent, PlanOriginalPrice, PlanTypes } from '@zipari/shared-sbp-models';
import {
    AttributeHighlight,
    AttributeHighlightByPlanTypeModel,
    defaultAttributeHighlightByPlanType,
    defaultDisabledTooltipText,
    SubsidyText,
    subsidyTextObj,
    goodFitMessage,
    viewDetailsModalConfig,
    defaultViewDetailsTitle,
    removePlanIconConfig,
    viewPlanDetailsIconConfig,
    textPopUpModalConfig,
    defaultSelectedButtonConfig,
    defaultSelectButtonConfig,
    defaultViewDetailConfig,
} from './plan-card.constants';
import { PlanCardBadge, PlanCardBadgeConfig } from '../plan-card-badge/plan-card-badge.constants';
import { getBadgesForPlan } from '../plan-card-badge/plan-card-badge-helpers';
import { FormattingService } from '@zipari/design-system';
import { ViewDetailConfig } from '../plan-detail-config.model';

@Component({
    selector: 'plan-card',
    templateUrl: './plan-card.component.html',
    styleUrls: ['./plan-card.component.scss'],
    animations: [fade, slideRight, accordion],
})
export class PlanCardComponent implements OnInit, OnChanges {
    @Input() numOfApplicants: number;
    @Input() plan: Plan;
    @Input() isFamily: boolean = false;
    @Input() planDetailConfigs: any;
    @Input() canCompare = true;
    @Input() isCompareDisabled = false;
    @Input() isCompareLocked = false;
    @Input() isSelectDisabled = false;
    @Input() hideSelection;
    @Input() starRatingConfig;
    @Input() subsidyAmount: number;
    @Input() showActions = true;
    @Input() showPrimaryBenefits = true;
    @Input() onlyPrice: boolean;
    @Input() hideHighlights: boolean;
    @Input() customButton: ButtonConfig;
    @Input() subsidyConfig: any;
    @Input() showStarRating: boolean = true;
    @Input() primaryBenefitsConfig: string[];
    @Input() selectBtnConfig: any;
    @Input() planSelectedBtnConfig: any;
    @Input() showRemovePlanIcon: boolean;
    @Input() compareTitle: string;
    @Input() showNewCard: boolean;
    @Input() subsidyText: SubsidyText;
    @Input() disabledTooltipText: string;
    @Input() unratedPriceHighlight: any;
    @Input() premiumIsCalculated: boolean;
    @Input() isQuoting: boolean;
    @Input() alignContent: boolean;
    @Input() isChildOnly: boolean;

    @Output('subsidyIconClicked') subsidyIconClicked = new EventEmitter<void>();
    @Output('customButtonClicked') customButtonClicked = new EventEmitter<void>();
    @Output('removePlanIconClicked') removePlanIconClicked = new EventEmitter<Plan>();
    @Output('select') select = new EventEmitter<Plan>();
    @Output('compare') compare = new EventEmitter<Plan>();
    badges: PlanCardBadge[];
    highlightAttributes: AttributeHighlight[];
    showLinks: boolean;
    showDetails: boolean;
    displayModal: boolean;
    goodFitMessage: string = goodFitMessage;
    showViewDetailsModal: boolean;
    removePlanIconConfig: PlanIconConfig = removePlanIconConfig;
    viewPlanDetailsIconConfig: PlanIconConfig = viewPlanDetailsIconConfig;
    viewDetailsModalConfig: ModalConfig = new ModalConfig(viewDetailsModalConfig);
    textPopUpModalConfig: ModalConfig = new ModalConfig(textPopUpModalConfig);
    modalContent: PlanModalContent;
    CXContext: PlanCxContext;
    highlightAttributesFormatted: boolean;
    originalPriceObj: PlanOriginalPrice = null;
    _subsidyText: SubsidyText = subsidyTextObj;
    planBenefitLinkLabelMap: Record<string, unknown> = {};

    constructor(
        public configService: ConfigService,
        public safeNumberPipe: SafeNumberPipe,
        public analyticsService: AnalyticsService,
        public planUtilService: PlanUtilService,
        public formattingService: FormattingService
    ) {}

    _attributeHighlightByPlanType = defaultAttributeHighlightByPlanType;

    get attributeHighlightByPlanType() {
        const globalConfig: any = this.configService.getPageConfig('global');
        if (globalConfig.attributeHighlightByPlanType) {
            // Merge config highlights over existing ones (plan-card.constant) if flag provided, else use only the highlights provided in the config
            const mergedHighlights = Object.assign(this._attributeHighlightByPlanType, globalConfig.attributeHighlightByPlanType);
            return globalConfig.mergeHighlights ? mergedHighlights : globalConfig.attributeHighlightByPlanType;
        }

        return this._attributeHighlightByPlanType;
    }

    @Input('overrideAttributeHighlightByPlanType')
    public set overrideAttributeHighlightByPlanType(overrideAttributeHighlightByPlanType: AttributeHighlightByPlanTypeModel) {
        if (overrideAttributeHighlightByPlanType) {
            this._attributeHighlightByPlanType = cloneObject(overrideAttributeHighlightByPlanType);
        }
    }

    get primaryBenefits() {
        let benefits = this.plan.primary_benefits;
        if (!!this.plan.primary_benefits && !!this.primaryBenefitsConfig) {
            benefits = this.primaryBenefitsConfig
                .map((key) => {
                    return this.plan.primary_benefits.find(({ label }) => label.trim().toLowerCase() === key);
                })
                .filter((obj) => !!obj);
        }
        return benefits;
    }

    get pdfUrl(): string {
        const { documents: { plan_contract: { document_id = undefined } = {} } = {} } = this.plan || {};
        return document_id;
    }

    get selectButtonConfig(): ButtonConfig {
        const tooltipText = this.disabledTooltipText || defaultDisabledTooltipText;

        return {
            ...defaultSelectButtonConfig,
            tooltip: this.isSelectDisabled ? tooltipText : null,
            ...this.selectBtnConfig,
        };
    }

    get selectedButtonConfig(): ButtonConfig {
        return { ...defaultSelectedButtonConfig, ...this.planSelectedBtnConfig };
    }

    get viewDetailConfig(): ViewDetailConfig {
        return { ...defaultViewDetailConfig, ...this.planDetailConfigs?.viewDetailConfig };
    }

    get subsidyTextConfig() {
        let subsidyText = Object.assign(this._subsidyText, this.subsidyText || {});
        if (this.originalPriceObj && this.subsidyAmount) {
            const subsidyDisplayAmount = this.formattingService.restructureValueBasedOnFormat(this.subsidyAmount, {
                format: 'CURRENCY',
            });
            let context = {
                subsidyAmount: subsidyDisplayAmount,
                originalPrice: this.originalPriceObj.originalPrice,
            };
            subsidyText['subsidyLabel'] = stringBuilder(subsidyText.subsidyLabel, context);
            subsidyText['originalPriceLabel'] = stringBuilder(subsidyText.originalPriceLabel, context);
        }
        return subsidyText;
    }

    get isPlanMedical(): boolean {
        return this.plan.plan_type === 'medical' || this.plan.plan_type === 'health';
    }

    get documentList(): Array<any> {
        if (this.planDetailConfigs?.displayDocuments) {
            const documentList = [];
            this.planDetailConfigs.displayDocuments.forEach((item) => {
                if (this.plan.documents && this.plan.documents[item.type]) {
                    documentList.push({
                        label: item.label,
                        document_id: this.plan.documents[item.type].document_id,
                    });
                }
            });
            return documentList;
        } else {
            return this.plan.documents ? Object.keys(this.plan.documents).map((i) => this.plan.documents[i]) : [];
        }
    }

    get getNoteForOMNIAPlan() {
        if (
            (this.plan['display_name'] && this.plan['display_name'].toUpperCase().indexOf('OMNIA') > -1) ||
            (this.plan['name'] && this.plan['name'].toUpperCase().indexOf('OMNIA') > -1)
        ) {
            return this.planDetailConfigs?.hasOwnProperty('omnia_note')
                ? this.planDetailConfigs.omnia_note
                : 'Note: You are viewing OMNIA Tier 1 benefits. You doctor must be in this network to receive this benefit.';
        }
    }

    get isFamilyHighlightAttributes(): boolean {
        // preventFamilyHighlightAttributes is used to disable the feature implemented in CE-14880
        const globalConfig: any = this.configService.getPageConfig('global');
        const preventFamilyHighlightAttributes = !!globalConfig.preventFamilyHighlightAttributes;
        return this.isFamily && this.isPlanMedical && !this.isChildOnly && !preventFamilyHighlightAttributes;
    }

    formatDocumentName(name: string) {
        return name
            .split('_')
            .join(' ')
            .replace(/\b\w/g, function (l) {
                return l.toUpperCase();
            });
    }

    handlePropAndFallbacks(prop: string, fallback: Array<string> = []): number | string {
        let returnVal: number | string = 0;
        if (prop === '') {
            return prop;
        }
        // concatenate the prop and the fallbacks to look for both in a loop
        const potentialProps: Array<string> = [prop].concat(fallback);

        // loop through and try to find the value for each prop
        for (let i = 0; i < potentialProps.length; i++) {
            returnVal = this.safeNumberPipe.transform(this.plan, potentialProps[i]);

            if (!returnVal || returnVal === 0) {
                const foundBenefit: Benefit = (this.plan.benefits || []).find((benefit: Benefit) => benefit.label === potentialProps[i]);

                if (foundBenefit && (typeof foundBenefit.value === 'number' || foundBenefit.value.indexOf('$') >= 0)) {
                    returnVal = foundBenefit.value;
                } else {
                    returnVal = 0;
                }
            }

            if (!!returnVal || returnVal === 0) {
                break;
            }
        }

        return returnVal;
    }

    ngOnInit(): void {
        this.CXContext = {
            plan_type: this.plan.plan_type,
            metal_tier: this.plan.metal_tier,
            max_out_of_pocket: this.plan.max_out_of_pocket,
            max_out_of_pocket_family: this.plan.max_out_of_pocket_family,
            deductible: this.plan.deductible,
            external_id: this.plan.external_id,
            display_name: this.plan.display_name,
            isHMO: this.plan.hmo,
            price: this.plan.price,
            index: this.plan.index,
            numOfApplicants: 0,
        };

        if (!!this.numOfApplicants !== null || this.numOfApplicants === 0) {
            this.CXContext['numOfApplicants'] = this.numOfApplicants;
        }

        this.formatLinks();
        this.formatHighlightAttributes();
        this.planBenefitLinkLabelMapping();

        if (this.viewDetailsModalConfig?.header) {
            this.viewDetailsModalConfig.header.title = this.plan?.display_name || defaultViewDetailsTitle;
        }
    }

    planBenefitLinkLabelMapping() {
        const planSelectionConfig = this.configService.getPageConfig('plan_selection');
        this.planBenefitLinkLabelMap =
            planSelectionConfig && planSelectionConfig['planDetailsLinkText'] ? planSelectionConfig['planDetailsLinkText'] : {};
    }

    formatLinks() {
        if (this.planDetailConfigs && this.planDetailConfigs.links) {
            this.planDetailConfigs.links.forEach((item) => {
                item.link = stringBuilder(item.link, this.plan);
            });
        }
    }

    formatHighlightAttributes() {
        let attributeHighlights: Array<any> =
            this.attributeHighlightByPlanType[this.isFamilyHighlightAttributes ? 'medical_family' : this.plan.plan_type];
        if (!attributeHighlights) {
            attributeHighlights = this.attributeHighlightByPlanType.default;
        }

        this.highlightAttributes = attributeHighlights
            .filter((highlightConfig: AttributeHighlight) => {
                if (this.onlyPrice) {
                    return highlightConfig.prop === 'price';
                }

                return highlightConfig;
            })
            .map((highlightConfig: AttributeHighlight) => {
                const formattedHighlightConfig = cloneObject(highlightConfig);
                formattedHighlightConfig.value = this.handlePropAndFallbacks(
                    formattedHighlightConfig.prop,
                    formattedHighlightConfig.fallback
                );
                formattedHighlightConfig.value = this.formattingService.restructureValueBasedOnFormat(
                    formattedHighlightConfig.value,
                    formattedHighlightConfig
                );

                if (highlightConfig.prop === 'price') {
                    const originalPrice = this.formattingService.restructureValueBasedOnFormat(this.plan.price, formattedHighlightConfig);
                    let discountedPrice;
                    // unique functionality for EDE or to be used elsewhere to be able to display a discounted price to
                    // the user alongside the original price
                    if (this.plan.hasOwnProperty('discountedPrice')) {
                        discountedPrice = this.formattingService.restructureValueBasedOnFormat(
                            this.plan.discountedPrice.toString().replace(/,/g, ''),
                            {
                                ...formattedHighlightConfig,
                                prop: 'discountedPrice',
                            }
                        );

                        if (originalPrice !== discountedPrice) {
                            formattedHighlightConfig.value = this.getStrikethroughPrice(originalPrice, discountedPrice);
                            formattedHighlightConfig.originalPrice = originalPrice;
                            formattedHighlightConfig.discountedPrice = discountedPrice;
                            formattedHighlightConfig.subsidyAmount = this.subsidyAmount;
                        } else {
                            formattedHighlightConfig.value = discountedPrice;
                        }
                    } else if (this.subsidyAmount && this.plan.plan_variation && Number.parseInt(this.plan.plan_variation) >= 1) {
                        let discountedPriceNum: number = Number(this.plan.price) - this.subsidyAmount;
                        discountedPriceNum = discountedPriceNum < 0 ? 0 : discountedPriceNum;
                        discountedPrice = this.formattingService.restructureValueBasedOnFormat(
                            discountedPriceNum,
                            formattedHighlightConfig
                        );
                        formattedHighlightConfig.value = this.getStrikethroughPrice(originalPrice, discountedPrice);
                        formattedHighlightConfig.originalPrice = originalPrice;
                        formattedHighlightConfig.discountedPrice = discountedPrice;
                        formattedHighlightConfig.subsidyAmount = this.subsidyAmount;
                    }

                    // Unique functionality for MedSupp rates
                    // If premium has not yet been manually calculated, allow option to display configurable pre-calculated label/value
                    if (this.plan.plan_type == PlanTypes.medicare_supplemental && this.unratedPriceHighlight && !this.premiumIsCalculated) {
                        formattedHighlightConfig.label = this.unratedPriceHighlight.label || formattedHighlightConfig.label;
                        formattedHighlightConfig.value = this.unratedPriceHighlight.value || formattedHighlightConfig.value;
                    }

                    this.originalPriceObj = formattedHighlightConfig;
                }

                return formattedHighlightConfig;
            });

        this.highlightAttributesFormatted = true;
    }

    modalAction(action, content?) {
        switch (action) {
            case 'display':
                this.modalContent = content;
                this.displayModal = true;
                break;
            case 'close':
                this.displayModal = false;
                break;
            case 'open':
                this.displayModal = false;
                window.open(this.modalContent.link, '_blank');
                break;
        }
    }

    getStrikethroughPrice(original, discount) {
        return `${discount} <p class="t-alt t-data t-strikethrough u-inline-b">${original}</p>`;
    }

    ngOnChanges(changes) {
        if ('plan' in changes && this.plan) {
            if (Array.isArray(this.plan)) {
                this.plan = this.plan[0];
            }
            this.plan = this.planUtilService.formatBenefitsOnPlan(this.plan);
            this.badges = this.getBadgesForPlan(this.plan);
        }
    }

    toggleSelection() {
        if (this.plan.selected) {
            this.analyticsService.dispatchAnalytics(
                {
                    GAKey: validGAEvents['plan_removed-from-cart'],
                    CXKey: validCXEvents['plan_removed-from-cart'],
                    PosthogKey: validPosthogEvents['plan_removed-from-cart'],
                },
                this.CXContext
            );

            this.planUtilService.removePlanFromShoppingCart(this.plan, this.isQuoting);
        } else {
            this.analyticsService.dispatchAnalytics(
                {
                    GAKey: validGAEvents['plan_added-to-cart'],
                    CXKey: validCXEvents['plan_added-to-cart'],
                    PosthogKey: validPosthogEvents['plan_added-to-cart'],
                },
                this.CXContext
            );

            this.planUtilService.addPlanFromShoppingCart(this.plan);
        }

        this.select.emit(this.plan);
    }

    toggleComparison() {
        const emittedPlan = this.originalPriceObj?.discountedPrice
            ? { ...this.plan, subsidyText: { ...this.originalPriceObj, ...this.subsidyTextConfig } }
            : this.plan;
        this.compare.emit(emittedPlan);
    }

    toggleDetailsModal(): void {
        this.showViewDetailsModal = !this.showViewDetailsModal;
    }

    toggleDetails(): void {
        this.showDetails = !this.showDetails;

        // dispatch analytics
        if (this.showDetails) {
            this.analyticsService.dispatchAnalytics(
                {
                    GAKey: validGAEvents['plan-details_viewed'],
                    CXKey: validCXEvents['plan-details_viewed'],
                    PosthogKey: validPosthogEvents['plan-details_viewed'],
                },
                this.CXContext
            );
        }
    }

    /**
     * emit analytics event when the user clicks a link in a plan card's details section
     *
     * @param link a link obj from this.planDetailConfigs.links OR a benefit object from this.plan.benefits
     * @param isBenefitsLink true if the link param is a benefit object, false if the link param is not a benefit object
     */
    linkClicked(link: Benefit | any, isBenefitsLink: boolean = false): void {
        this.analyticsService.dispatchAnalytics(
            {
                GAKey: validGAEvents['plans-list_link_clicked'],
                CXKey: validCXEvents['plans-list_link_clicked'],
                PosthogKey: validPosthogEvents['plan-details_link_clicked'],
            },
            {
                link: isBenefitsLink ? link?.value : link?.url,
                label: link?.label,
                ...this.CXContext,
            }
        );
    }

    public getBadgesForPlan(plan: Plan): Array<PlanCardBadge> {
        const globalConfig: any = this.configService.getPageConfig('global');
        const badgeConfig: PlanCardBadgeConfig = globalConfig?.plan_card?.badges;
        if (!badgeConfig?.enabled) return undefined;
        return getBadgesForPlan(plan, badgeConfig);
    }
}
